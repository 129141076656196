import React, { useEffect, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import * as styles from "./index.module.less"
import { Button, Col, FormGroup, Input, Row } from "reactstrap"
// import get from "lodash/get"
import { useAuth } from "@auth/useAuth"
import OnlyAuthorized from "@auth/OnlyAuthorized"
import { format } from "date-fns"
import { toast } from "react-toastify"

const CouponsPage = () => {
  const { makeRequest, isAuthenticated } = useAuth() || {}

  const [coupons, setCoupons] = useState([])

  useEffect(() => {
    if (isAuthenticated) fetchCoupons()
  }, [isAuthenticated])

  const fetchCoupons = () => {
    makeRequest("coupons/all")
      .then((res) => {
        if (res.coupons) setCoupons(res.coupons)
      })
      .catch((e) => {
        console.log(e)
        toast.warn("Failed to add the coupon code")
      })
  }

  const [coupon_code, setCode] = useState("")
  const [discount_percentage, setDiscount] = useState("")
  const [expiry_date, setEd] = useState("")

  const add = async () => {
    await makeRequest("coupons/create", {
      coupon_code,
      discount_percentage: Number(discount_percentage),
      expiry_date,
    })
      .then((res) => {
        if (res.coupons) setCoupons(res.coupons)
        if (res.coupon) {
          toast.success("Coupon added")
          reset()
        } else {
          toast.error(
            "Failed to add the coupon code. The Coupon code should be unique."
          )
        }
      })
      .catch((e) => {
        console.log(e)
        toast.error("Failed to add the coupon code")
      })
  }

  const reset = () => {
    if (process.env.NODE_ENV === "development") return
    setCode("")
    setDiscount("")
    setEd("")
  }

  const disableCoupon = (e, id) => {
    e.preventDefault()
    makeRequest("coupons/disable", {
      id,
    })
      .then((res) => {
        if (res.coupons) {
          setCoupons(res.coupons)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const enableCoupon = (e, id) => {
    e.preventDefault()
    makeRequest("coupons/enable", {
      id,
    })
      .then((res) => {
        if (res.coupons) {
          setCoupons(res.coupons)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <SEO title="Coupons" />
      <Layout title={"Coupons"}>
        <OnlyAuthorized permission={"manage:space-providers"}>
          <Row>
            <Col lg={3}>
              <FormGroup>
                <label htmlFor="">Coupon Code</label>
                <Input
                  type={"text"}
                  className={styles.coupon_code_input}
                  value={coupon_code}
                  onChange={(e) => setCode(e.target.value.toUpperCase())}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <label htmlFor="">Discount %</label>
                <Input
                  type={"number"}
                  min={0}
                  max={20}
                  value={discount_percentage}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <label htmlFor="">Expiry Date</label>
                <Input
                  type={"date"}
                  min={format(new Date(), "yyyy-MM-dd")}
                  value={expiry_date}
                  onChange={(e) => setEd(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={12}>
              <FormGroup>
                <Button
                  type={"submit"}
                  color={"success"}
                  size={"sm"}
                  disabled={
                    !coupon_code || !discount_percentage || !expiry_date
                  }
                  onClick={add}
                >
                  Add
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <table className="table table-hover mt-5">
            <thead>
              <tr>
                <th>Coupon Code</th>
                {/*<th className={"text-center"}>Added on</th>*/}
                <th className={"text-center"}>Discount %</th>
                <th className={"text-center"}>Expiry date</th>
                {/*<th className={"text-center"}>Usage</th>*/}
                <th className={"text-center"} />
              </tr>
            </thead>
            <tbody>
              {coupons.map(
                ({
                  id,
                  coupon_code,
                  discount_percentage,
                  expiry_date,
                  status,
                }) => {
                  return (
                    <tr key={id}>
                      <td>{coupon_code}</td>
                      {/*<td className="text-center">Mar 10, 2021</td>*/}
                      <td className="text-center">{discount_percentage}%</td>
                      <td className="text-center">
                        {format(new Date(expiry_date), "MMM do, yyyy")}
                      </td>
                      {/*<td className="text-center">0</td>*/}
                      <td className="text-center">
                        {status === 0 ? (
                          <a
                            href={"/admin/coupons"}
                            className={"text-warning"}
                            onClick={(e) => enableCoupon(e, id)}
                          >
                            Enable
                          </a>
                        ) : (
                          <a
                            href={"/admin/coupons"}
                            className={"text-warning"}
                            onClick={(e) => disableCoupon(e, id)}
                          >
                            Disable
                          </a>
                        )}
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </OnlyAuthorized>
      </Layout>
    </>
  )
}

export default CouponsPage
